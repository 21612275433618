<script setup lang="ts">
interface IYaCaptchaExpose {
  destroy: Window["smartCaptcha"]["destroy"];
  getResponse: Window["smartCaptcha"]["getResponse"];
  init: () => void;
  reset: Window["smartCaptcha"]["reset"];
}

const { yandexCaptcha } = usePublicConfig();

const CAPTCHA_KEY = yandexCaptcha.value;
const CAPTCHA_URL = "https://captcha-api.yandex.ru/captcha.js";

const element = ref<HTMLDivElement | null>(null);
const widgetId = ref("");
const emit = defineEmits<{
  tokenChanged: [token: string | undefined];
}>();

const destroy = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.destroy(widgetId.value);
};

const getResponse = () => {
  if (!window.smartCaptcha) {
    return;
  }

  return window.smartCaptcha.getResponse(widgetId.value);
};

const reset = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.reset(widgetId.value);
};

const init = () => {
  if (!window.smartCaptcha) {
    return;
  }

  if (element.value) {
    const windgetId = window.smartCaptcha.render(element.value, {
      sitekey: CAPTCHA_KEY,
      hl: "ru",
    });
    widgetId.value = windgetId;
    window.smartCaptcha.subscribe(windgetId, "success", (token) =>
      emit("tokenChanged", token),
    );
  }
};

onMounted(() => {
  if (window.smartCaptcha) {
    init();
    return;
  }
  useHead({
    script: [{ src: CAPTCHA_URL, async: true, defer: true, onload: init }],
  });
});

defineExpose<IYaCaptchaExpose>({
  destroy,
  init,
  getResponse,
  reset,
});
</script>

<template>
  <div ref="element" class="captcha" />
</template>

<style lang="postcss">
.captcha {
  height: 102px;
}
</style>
